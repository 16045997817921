<template>
    <div>
        <PostIt>{{ $t('example_content' ) }}</PostIt>
        <h2 class="centered">
            {{ $t("skillfinder") }}
        </h2>

        <transition
            name="pushIn"
            mode="out-in"
        >
            <div v-if="!results">
                <p class="centered">
                    {{ $t("skillfinder_subtitle") }}
                </p>
            </div>
        </transition>

        <div class="skillfinder-select">
            <transition-group
                name="fade"
                class="skillfinder-row"
                mode="out-in"
            >
                <div
                    key="level-label"
                    class="text"
                >
                    {{ $t("skillfinder_want_to_find") }}
                </div>
                <div
                    key="level-select"
                    class="dropdown"
                >
                    <v-select
                        v-model="level"
                        class="sf-select"
                        :searchable="false"
                        :clearable="false"
                        :placeholder="'select Level'"
                        :options="[
                            'a beginner',
                            'an intermediate',
                            'an expert',
                            'a master'
                        ]"
                    />
                </div>
                <div
                    v-if="level"
                    key="skill-label"
                    class="text"
                >
                    {{ $t("skillfinder_in") }}
                </div>
                <div
                    v-if="level"
                    key="skill-select"
                    class="dropdown"
                >
                    <v-select
                        v-model="skill"
                        class="sf-select"
                        :placeholder="'choose skill'"
                        :clearable="false"
                        :options="[
                            'Interior Design',
                            'Management',
                            'Leadership',
                            'Photography',
                            'Product Creation',
                            'Product Design',
                            'React',
                            'Sewing',
                            'Sourcing',
                            'Vue'
                        ]"
                    />
                </div>
                <div
                    v-if="skill"
                    key="location-label"
                    class="text"
                >
                    {{ $t("skillfinder_at") }}
                </div>
                <div
                    v-if="skill"
                    key="location-select"
                    class="dropdown"
                >
                    <v-select
                        v-model="location"
                        class="sf-select"
                        :searchable="false"
                        :placeholder="'select location'"
                        :clearable="false"
                        :options="['any location', 'Frankfurt', 'London']"
                    />
                </div>
            </transition-group>
        </div>
        <LoadingWrapper :is-loading="loading">
            <div
                v-if="results"
                class="results"
            >
                <div class="meta padding-medium centered">
                    {{ $t("skillfinder_i_found") }} 3 {{ level }} {{ $t("skillfinder_in") }} {{ skill }} {{ $t("skillfinder_at") }} {{ location }}.
                </div>
                <div class="flex pbottom-large">
                    <PersonTile
                        v-for="(person, i) in resultPeople"
                        :key="person.name"
                        :person="person"
                    />
                </div>
            </div>
        </LoadingWrapper>
    </div>
</template>
                languages: [
                    { name: "Deutsch", level: 4 },
                    { name: "English", level: 4, cert: "C1" },
                    { name: "Español", level: 2 }
                ],
<script>
import vSelect from "vue-select";
import PersonTile from "@web/components/PersonTile";

export default {
    components: {
        "v-select": vSelect,
        PersonTile
    },
    data() {
        return {
            skill: null,
            level: null,
            location: null,
            loading: false,
            results: false,
            resultPeople: [
                {
                    name: "Michael Keaton",
                    meta: "React Dev • E-Commerce Squad",
                    avatar: "https://randomuser.me/api/portraits/men/81.jpg",
                    bg:
                            "https://images.unsplash.com/photo-1582601062994-c7fd281951a1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
                },
                {
                    name: "Jamal Wentworth",
                    meta: "Frontend Developer • E-Commerce Squad",
                    avatar:
                            "https://pbs.twimg.com/profile_images/953000038967468033/n4Ngwvi7.jpg",
                    bg:
                            "https://images.unsplash.com/photo-1582577852510-e4535bcfc456?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
                },
                {
                    name: "Mary Flannigan",
                    meta: "UI Developer • E-Commerce Squad",
                    avatar: "https://randomuser.me/api/portraits/women/57.jpg",
                    bg:
                            "https://images.unsplash.com/photo-1582604128732-6db6bfc7be66?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
                }
            ]
        };
    },
    watch: {
        skill: function(oldSkill, newSkill) {
            this.setLoading();
        },
        level: function(oldSkill, newSkill) {
            this.setLoading();
        },
        location: function(oldSkill, newSkill) {
            this.setLoading();
        }
    },
    methods: {
        setLoading() {
            if (this.skill && this.level && this.location) {
                this.loading = true;
                setTimeout(() => {
                    this.results = true;
                    this.doneLoading();
                }, 1500);
            }
        },
        doneLoading() {
            this.loading = false;
        }
    }
};
</script>

<style lang="scss" scoped>
    h2 + div p {
        margin-top: -1rem;
        padding-bottom: 2rem;
    }

    .skillfinder-row {
        display: flex;
        align-items: center;
        justify-content: center;
        .text,
        .dropdown {
            padding: 0.5rem;
        }
        .dropdown {
            min-width: 200px;
            margin-top: 2px;
        }
    }

    .person-tile {
        flex: 1;
        outline: none;
        margin: 0 0.5rem;
    }
</style>

<style lang="scss">
    .sf-select .vs__dropdown-toggle,
    .sf-select .vs__dropdown-menu {
        //background: #dfe5fb;
        border: none;
        border-radius: 0;
        border-bottom: 2px solid var(--low-contrast, #{$light-grey});
    }
    .sf-select .vs__dropdown-menu {
        border-bottom: none;
    }

    .sf-select .vs__actions {
        transform: scale(0.7);
    }

    .sf-select .vs__clear,
    .sf-select .vs__open-indicator {
        fill: var(--medium-contrast, #{$mid-grey});
    }
</style>
