var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PostIt',[_vm._v(_vm._s(_vm.$t('example_content' )))]),_c('h2',{staticClass:"centered"},[_vm._v(" "+_vm._s(_vm.$t("skillfinder"))+" ")]),_c('transition',{attrs:{"name":"pushIn","mode":"out-in"}},[(!_vm.results)?_c('div',[_c('p',{staticClass:"centered"},[_vm._v(" "+_vm._s(_vm.$t("skillfinder_subtitle"))+" ")])]):_vm._e()]),_c('div',{staticClass:"skillfinder-select"},[_c('transition-group',{staticClass:"skillfinder-row",attrs:{"name":"fade","mode":"out-in"}},[_c('div',{key:"level-label",staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("skillfinder_want_to_find"))+" ")]),_c('div',{key:"level-select",staticClass:"dropdown"},[_c('v-select',{staticClass:"sf-select",attrs:{"searchable":false,"clearable":false,"placeholder":'select Level',"options":[
                        'a beginner',
                        'an intermediate',
                        'an expert',
                        'a master'
                    ]},model:{value:(_vm.level),callback:function ($$v) {_vm.level=$$v},expression:"level"}})],1),(_vm.level)?_c('div',{key:"skill-label",staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("skillfinder_in"))+" ")]):_vm._e(),(_vm.level)?_c('div',{key:"skill-select",staticClass:"dropdown"},[_c('v-select',{staticClass:"sf-select",attrs:{"placeholder":'choose skill',"clearable":false,"options":[
                        'Interior Design',
                        'Management',
                        'Leadership',
                        'Photography',
                        'Product Creation',
                        'Product Design',
                        'React',
                        'Sewing',
                        'Sourcing',
                        'Vue'
                    ]},model:{value:(_vm.skill),callback:function ($$v) {_vm.skill=$$v},expression:"skill"}})],1):_vm._e(),(_vm.skill)?_c('div',{key:"location-label",staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("skillfinder_at"))+" ")]):_vm._e(),(_vm.skill)?_c('div',{key:"location-select",staticClass:"dropdown"},[_c('v-select',{staticClass:"sf-select",attrs:{"searchable":false,"placeholder":'select location',"clearable":false,"options":['any location', 'Frankfurt', 'London']},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}})],1):_vm._e()])],1),_c('LoadingWrapper',{attrs:{"is-loading":_vm.loading}},[(_vm.results)?_c('div',{staticClass:"results"},[_c('div',{staticClass:"meta padding-medium centered"},[_vm._v(" "+_vm._s(_vm.$t("skillfinder_i_found"))+" 3 "+_vm._s(_vm.level)+" "+_vm._s(_vm.$t("skillfinder_in"))+" "+_vm._s(_vm.skill)+" "+_vm._s(_vm.$t("skillfinder_at"))+" "+_vm._s(_vm.location)+". ")]),_c('div',{staticClass:"flex pbottom-large"},_vm._l((_vm.resultPeople),function(person,i){return _c('PersonTile',{key:person.name,attrs:{"person":person}})}),1)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }